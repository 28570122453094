.sidebar {
  flex: 1;
  min-height: 100vh;
  background-color: white;
}

.sidebar .top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .top .logo {
  font-size: 20px;
  font-weight: 500;
  color: #6439ff;
}

.sidebar hr {
  height: 0;
  border: 0.5px solid rgb(230, 227, 227);
}

.sidebar .center {
  padding-left: 10px;
}

.sidebar .center ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar .center ul .title {
  font-size: 10px;
  font-weight: 500;
  color: #999;
  padding-top: 15px;
  margin-bottom: 5px;
}

.sidebar .center ul li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.sidebar .center ul li:hover {
  background-color: rgb(214, 205, 255);
}

.sidebar .center ul li .icon {
  font-size: 18px;
  color: #0056a6;
}

.sidebar .center ul li span {
  font-size: 13px;
  font-weight: 500;
  color: #888;
  margin-left: 10px;
}

.sidebar .bottom {
  display: flex;
  align-items: center;
  margin: 10px;
}

.sidebar .bottom .colorOption {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #0056a6;
  cursor: pointer;
  margin: 5px;
}

.sidebar .bottom .colorOption:nth-child(1) {
  background-color: whitesmoke;
}

.sidebar .bottom .colorOption:nth-child(2) {
  background-color: #333;
}

.sidebar .bottom .colorOption:nth-child(3) {
  background-color: darkblue;
}
