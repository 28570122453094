@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Roboto", sans-serif !important;
}
.typing-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-5px);
  }
}

#scrollableDiv::-webkit-scrollbar {
  width: 7px;
  display: none;
}

#scrollableDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 12px;
}

#scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

#scrollableDiv::-webkit-scrollbar-corner {
  background: transparent;
}
#scrollableDivDashboard::-webkit-scrollbar {
  width: 7px;
}

#scrollableDivDashboard::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

#scrollableDivDashboard::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 12px;
}

#scrollableDivDashboard::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

#scrollableDivDashboard::-webkit-scrollbar-corner {
  background: transparent;
}

.swiper {
  width: 100%;
  height: 200px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide {
  background-color: #0056a6;
}
.mySwiper .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  opacity: 0.4;
  transition: all 0.3s ease;
}
.mySwiper .swiper-pagination-bullet-active {
  width: 14px;
  height: 7px;
  border-radius: 2px !important;
  opacity: 1;
}
.swiper-button-prev-custom,
.swiper-button-next-custom {
  background-color: #00a8e8;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
  background-color: #0056a6;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none !important;
}
.shadowdashboard,
.shadowchat {
  box-shadow: 1px 2px 3px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
}
